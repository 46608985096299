export const BRIDGE_MAP = {
  '1': {
    '0x0642026E7f0B6cCaC5925b4E7Fa61384250e1701': {
      decimals: 18,
      symbol: 'H2O',
      origin: ['H2O'],
      destination: ['H2O'],
      swappable: [],
    },
    '0x0ab87046fBb341D058F17CBC4c1133F25a20a52f': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0x0f2D719407FdBeFF09D87557AbB7232601FD9F29': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0x12f79f8c1A6e47a9b5F0796FDb008Bdc182fa19e': {
      decimals: 18,
      symbol: 'JEWEL',
      origin: ['JEWEL'],
      destination: ['JEWEL'],
      swappable: [],
    },
    '0x1B84765dE8B7566e4cEAF4D0fD3c5aF52D3DdE4F': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['CCTP.USDC', 'DAI', 'USDC', 'USDT', 'nUSD', 'synFRAX'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
        '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        '0x6c3ea9036406852006290770BEdFcAbA0e23A0e8',
        '0x853d955aCEf822Db058eb8505911ED77F175b99e',
        '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        '0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E',
      ],
    },
    '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599': {
      decimals: 8,
      symbol: 'WBTC',
      origin: ['WBTC'],
      destination: ['WBTC'],
      swappable: [],
    },
    '0x514910771AF9Ca656af840dff83E8264EcF986CA': {
      decimals: 18,
      symbol: 'LINK',
      origin: ['LINK'],
      destination: ['LINK'],
      swappable: [],
    },
    '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0': {
      decimals: 18,
      symbol: 'LUSD',
      origin: ['CCTP.USDC', 'DAI', 'USDC', 'USDT', 'nUSD', 'synFRAX'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x1B84765dE8B7566e4cEAF4D0fD3c5aF52D3DdE4F',
        '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        '0x6c3ea9036406852006290770BEdFcAbA0e23A0e8',
        '0x853d955aCEf822Db058eb8505911ED77F175b99e',
        '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        '0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E',
      ],
    },
    '0x6982508145454Ce325dDbE47a25d4ec3d2311933': {
      decimals: 18,
      symbol: 'PEPE',
      origin: ['PEPE'],
      destination: ['PEPE'],
      swappable: [],
    },
    '0x6B175474E89094C44Da98b954EedeAC495271d0F': {
      decimals: 18,
      symbol: 'DAI',
      origin: ['CCTP.USDC', 'DAI', 'USDC', 'USDT', 'nUSD', 'synFRAX'],
      destination: ['CCTP.USDC', 'DAI', 'nUSD'],
      swappable: [
        '0x1B84765dE8B7566e4cEAF4D0fD3c5aF52D3DdE4F',
        '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
        '0x6c3ea9036406852006290770BEdFcAbA0e23A0e8',
        '0x853d955aCEf822Db058eb8505911ED77F175b99e',
        '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        '0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E',
      ],
    },
    '0x6c3ea9036406852006290770BEdFcAbA0e23A0e8': {
      decimals: 6,
      symbol: 'PYUSD',
      origin: ['CCTP.USDC', 'DAI', 'USDC', 'USDT', 'nUSD', 'synFRAX'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x1B84765dE8B7566e4cEAF4D0fD3c5aF52D3DdE4F',
        '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
        '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        '0x853d955aCEf822Db058eb8505911ED77F175b99e',
        '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        '0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E',
      ],
    },
    '0x71Ab77b7dbB4fa7e017BC15090b2163221420282': {
      decimals: 18,
      symbol: 'HIGH',
      origin: ['HIGH'],
      destination: ['HIGH'],
      swappable: [],
    },
    '0x73968b9a57c6E53d41345FD57a6E6ae27d6CDB2F': {
      decimals: 18,
      symbol: 'SDT',
      origin: ['SDT'],
      destination: ['SDT'],
      swappable: [],
    },
    '0x853d955aCEf822Db058eb8505911ED77F175b99e': {
      decimals: 18,
      symbol: 'FRAX',
      origin: ['CCTP.USDC', 'DAI', 'USDC', 'USDT', 'nUSD', 'synFRAX'],
      destination: ['CCTP.USDC', 'synFRAX'],
      swappable: [
        '0x1B84765dE8B7566e4cEAF4D0fD3c5aF52D3DdE4F',
        '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
        '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        '0x6c3ea9036406852006290770BEdFcAbA0e23A0e8',
        '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        '0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E',
      ],
    },
    '0x98585dFc8d9e7D48F0b1aE47ce33332CF4237D96': {
      decimals: 18,
      symbol: 'NEWO',
      origin: ['NEWO'],
      destination: ['NEWO'],
      swappable: [],
    },
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': {
      decimals: 6,
      symbol: 'USDC',
      origin: ['CCTP.USDC', 'DAI', 'USDC', 'USDT', 'nUSD', 'synFRAX'],
      destination: ['CCTP.USDC', 'USDC', 'nUSD'],
      swappable: [
        '0x1B84765dE8B7566e4cEAF4D0fD3c5aF52D3DdE4F',
        '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
        '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        '0x6c3ea9036406852006290770BEdFcAbA0e23A0e8',
        '0x853d955aCEf822Db058eb8505911ED77F175b99e',
        '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        '0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E',
      ],
    },
    '0xA8d7F5e7C78ed0Fa097Cc5Ec66C1DC3104c9bbeb': {
      decimals: 18,
      symbol: 'VSTA',
      origin: ['VSTA'],
      destination: ['VSTA'],
      swappable: [],
    },
    '0xBAac2B4491727D78D2b78815144570b9f2Fe8899': {
      decimals: 18,
      symbol: 'DOG',
      origin: ['DOG'],
      destination: ['DOG'],
      swappable: [],
    },
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2': {
      decimals: 18,
      symbol: 'WETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'ETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [],
    },
    '0xb753428af26E81097e7fD17f40c88aaA3E04902c': {
      decimals: 18,
      symbol: 'SFI',
      origin: ['SFI'],
      destination: ['SFI'],
      swappable: [],
    },
    '0xdAC17F958D2ee523a2206206994597C13D831ec7': {
      decimals: 6,
      symbol: 'USDT',
      origin: ['CCTP.USDC', 'DAI', 'USDC', 'USDT', 'nUSD', 'synFRAX'],
      destination: ['CCTP.USDC', 'USDT', 'nUSD'],
      swappable: [
        '0x1B84765dE8B7566e4cEAF4D0fD3c5aF52D3DdE4F',
        '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
        '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        '0x6c3ea9036406852006290770BEdFcAbA0e23A0e8',
        '0x853d955aCEf822Db058eb8505911ED77F175b99e',
        '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        '0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E',
      ],
    },
    '0xf0655DcEE37E5C0b70Fffd70D85f88F8eDf0AfF6': {
      decimals: 18,
      symbol: 'UNIDX',
      origin: ['UNIDX'],
      destination: ['UNIDX'],
      swappable: [],
    },
    '0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E': {
      decimals: 18,
      symbol: 'crvUSD',
      origin: ['CCTP.USDC', 'DAI', 'USDC', 'USDT', 'nUSD', 'synFRAX'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x1B84765dE8B7566e4cEAF4D0fD3c5aF52D3DdE4F',
        '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
        '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        '0x6c3ea9036406852006290770BEdFcAbA0e23A0e8',
        '0x853d955aCEf822Db058eb8505911ED77F175b99e',
        '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      ],
    },
  },
  '10': {
    '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85': {
      decimals: 6,
      symbol: 'USDC',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x67C10C397dD0Ba417329543c1a40eb48AAa7cd00',
        '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
        '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
        '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      ],
    },
    '0x0b5740c6b4a97f90eF2F0220651Cca420B868FfB': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0x121ab82b49B2BC4c7901CA46B8277962b4350204': {
      decimals: 18,
      symbol: 'WETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x809DC529f07651bD43A172e8dB6f4a7a0d771036',
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      ],
    },
    '0x28b42698Caf46B4B012CF38b6C75867E0762186D': {
      decimals: 18,
      symbol: 'UNIDX',
      origin: ['UNIDX'],
      destination: ['UNIDX'],
      swappable: [],
    },
    '0x5A5fFf6F753d7C11A56A52FE47a177a87e431655': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0x67C10C397dD0Ba417329543c1a40eb48AAa7cd00': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
        '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
        '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
        '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      ],
    },
    '0x7F5c764cBc14f9669B88837ca1490cCa17c31607': {
      decimals: 6,
      symbol: 'USDC.e',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
        '0x67C10C397dD0Ba417329543c1a40eb48AAa7cd00',
        '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
        '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
        '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      ],
    },
    '0x809DC529f07651bD43A172e8dB6f4a7a0d771036': {
      decimals: 18,
      symbol: 'nETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x121ab82b49B2BC4c7901CA46B8277962b4350204',
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      ],
    },
    '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9': {
      decimals: 18,
      symbol: 'sUSD',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
        '0x67C10C397dD0Ba417329543c1a40eb48AAa7cd00',
        '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
        '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      ],
    },
    '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58': {
      decimals: 6,
      symbol: 'USDT',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
        '0x67C10C397dD0Ba417329543c1a40eb48AAa7cd00',
        '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
        '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      ],
    },
    '0xD9eAA386cCD65F30b77FF175F6b52115FE454fD6': {
      decimals: 18,
      symbol: 'PLS',
      origin: ['PLS'],
      destination: ['PLS'],
      swappable: [],
    },
    '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1': {
      decimals: 18,
      symbol: 'DAI',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
        '0x67C10C397dD0Ba417329543c1a40eb48AAa7cd00',
        '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
        '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
        '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
      ],
    },
    '0xE3c82A836Ec85311a433fBd9486EfAF4b1AFbF48': {
      decimals: 18,
      symbol: 'H2O',
      origin: ['H2O'],
      destination: ['H2O'],
      swappable: [],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'ETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x121ab82b49B2BC4c7901CA46B8277962b4350204',
        '0x809DC529f07651bD43A172e8dB6f4a7a0d771036',
      ],
    },
    '0xd52f94DF742a6F4B4C8b033369fE13A41782Bf44': {
      decimals: 18,
      symbol: 'L2DAO',
      origin: ['L2DAO'],
      destination: ['L2DAO'],
      swappable: [],
    },
  },
  '25': {
    '0x396c9c192dd323995346632581BEF92a31AC623b': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: ['0xc21223249CA28397B4B6541dfFaEcC539BfF0c59'],
    },
    '0xFD0F80899983b8D46152aa1717D76cba71a31616': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0xbB0A63A6CA2071c6C4bcAC11a1A317b20E3E999C': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59': {
      decimals: 6,
      symbol: 'USDC',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: ['0x396c9c192dd323995346632581BEF92a31AC623b'],
    },
  },
  '56': {
    '0x03eFca7CEb108734D3777684F3C0A0d8ad652f79': {
      decimals: 18,
      symbol: 'H2O',
      origin: ['H2O'],
      destination: ['H2O'],
      swappable: [],
    },
    '0x0FE9778c005a5A6115cBE12b0568a2d50b765A51': {
      decimals: 18,
      symbol: 'NFD',
      origin: ['NFD'],
      destination: ['NFD'],
      swappable: [],
    },
    '0x130025eE738A66E691E6A7a62381CB33c6d9Ae83': {
      decimals: 18,
      symbol: 'JUMP',
      origin: ['JUMP'],
      destination: ['JUMP'],
      swappable: [],
    },
    '0x23b891e5C62E0955ae2bD185990103928Ab817b3': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['BUSD', 'nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x55d398326f99059fF775485246999027B3197955',
        '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      ],
    },
    '0x55d398326f99059fF775485246999027B3197955': {
      decimals: 18,
      symbol: 'USDT',
      origin: ['BUSD', 'nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x23b891e5C62E0955ae2bD185990103928Ab817b3',
        '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      ],
    },
    '0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63': {
      decimals: 18,
      symbol: 'HIGH',
      origin: ['HIGH'],
      destination: ['HIGH'],
      swappable: [],
    },
    '0x88918495892BAF4536611E38E75D771Dc6Ec0863': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d': {
      decimals: 18,
      symbol: 'USDC',
      origin: ['BUSD', 'nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x23b891e5C62E0955ae2bD185990103928Ab817b3',
        '0x55d398326f99059fF775485246999027B3197955',
        '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      ],
    },
    '0xa4080f1778e69467E905B8d6F72f6e441f9e9484': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0xaA88C603d142C371eA0eAC8756123c5805EdeE03': {
      decimals: 18,
      symbol: 'DOG',
      origin: ['DOG'],
      destination: ['DOG'],
      swappable: [],
    },
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56': {
      decimals: 18,
      symbol: 'BUSD',
      origin: ['BUSD', 'nUSD'],
      destination: ['BUSD', 'nUSD'],
      swappable: [
        '0x23b891e5C62E0955ae2bD185990103928Ab817b3',
        '0x55d398326f99059fF775485246999027B3197955',
        '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      ],
    },
  },
  '137': {
    '0x0A5926027d407222F8fe20f24cB16e103f617046': {
      decimals: 18,
      symbol: 'NFD',
      origin: ['NFD'],
      destination: ['NFD'],
      swappable: [],
    },
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270': {
      decimals: 18,
      symbol: 'WMATIC',
      origin: ['MATIC'],
      destination: ['MATIC'],
      swappable: [],
    },
    '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174': {
      decimals: 6,
      symbol: 'USDC.e',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
        '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89',
        '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
        '0xB6c473756050dE474286bED418B77Aeac39B02aF',
        '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      ],
    },
    '0x32ba7cF7d681357529013de6a2CDF93933C0dF3f': {
      decimals: 18,
      symbol: 'H2O',
      origin: ['H2O'],
      destination: ['H2O'],
      swappable: [],
    },
    '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359': {
      decimals: 6,
      symbol: 'USDC',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89',
        '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
        '0xB6c473756050dE474286bED418B77Aeac39B02aF',
        '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      ],
    },
    '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89': {
      decimals: 18,
      symbol: 'FRAX',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
        '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
        '0xB6c473756050dE474286bED418B77Aeac39B02aF',
        '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      ],
    },
    '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063': {
      decimals: 18,
      symbol: 'DAI',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
        '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89',
        '0xB6c473756050dE474286bED418B77Aeac39B02aF',
        '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      ],
    },
    '0xB6c473756050dE474286bED418B77Aeac39B02aF': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
        '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89',
        '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
        '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      ],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'MATIC',
      origin: ['MATIC'],
      destination: ['MATIC'],
      swappable: [],
    },
    '0xc2132D05D31c914a87C6611C10748AEb04B58e8F': {
      decimals: 6,
      symbol: 'USDT',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
        '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89',
        '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
        '0xB6c473756050dE474286bED418B77Aeac39B02aF',
      ],
    },
    '0xd8cA34fd379d9ca3C6Ee3b3905678320F5b45195': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0xeEe3371B89FC43Ea970E908536Fcddd975135D8a': {
      decimals: 18,
      symbol: 'DOG',
      origin: ['DOG'],
      destination: ['DOG'],
      swappable: [],
    },
    '0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
  },
  '250': {
    '0x0483a76D80D0aFEC6bd2afd12C1AD865b9DF1471': {
      decimals: 18,
      symbol: 'UNIDX',
      origin: ['UNIDX'],
      destination: ['UNIDX'],
      swappable: [],
    },
    '0x1852F70512298d56e9c8FDd905e02581E04ddb2a': {
      decimals: 18,
      symbol: 'synFRAX',
      origin: ['synFRAX'],
      destination: ['synFRAX'],
      swappable: [],
    },
    '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83': {
      decimals: 18,
      symbol: 'WFTM',
      origin: ['FTM'],
      destination: ['FTM'],
      swappable: [],
    },
    '0x67C10C397dD0Ba417329543c1a40eb48AAa7cd00': {
      decimals: 18,
      symbol: 'nETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [],
    },
    '0x78DE9326792ce1d6eCA0c978753c6953Cdeedd73': {
      decimals: 18,
      symbol: 'JUMP',
      origin: ['JUMP'],
      destination: ['JUMP'],
      swappable: [],
    },
    '0x91fa20244Fb509e8289CA630E5db3E9166233FDc': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0xE3c82A836Ec85311a433fBd9486EfAF4b1AFbF48': {
      decimals: 18,
      symbol: 'SDT',
      origin: ['SDT'],
      destination: ['SDT'],
      swappable: [],
    },
    '0xE55e19Fb4F2D85af758950957714292DAC1e25B2': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0xED2a7edd7413021d440b09D654f3b87712abAB66': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'FTM',
      origin: ['FTM'],
      destination: ['FTM'],
      swappable: [],
    },
  },
  '288': {
    '0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d': {
      decimals: 6,
      symbol: 'USDT',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc',
        '0x6B4712AE9797C199edd44F897cA09BC57628a1CF',
        '0xf74195Bb8a5cf652411867c5C2C5b8C2a402be35',
      ],
    },
    '0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc': {
      decimals: 6,
      symbol: 'USDC',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d',
        '0x6B4712AE9797C199edd44F897cA09BC57628a1CF',
        '0xf74195Bb8a5cf652411867c5C2C5b8C2a402be35',
      ],
    },
    '0x6B4712AE9797C199edd44F897cA09BC57628a1CF': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d',
        '0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc',
        '0xf74195Bb8a5cf652411867c5C2C5b8C2a402be35',
      ],
    },
    '0x96419929d7949D6A801A6909c145C8EEf6A40431': {
      decimals: 18,
      symbol: 'nETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        '0xd203De32170130082896b4111eDF825a4774c18E',
      ],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'ETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x96419929d7949D6A801A6909c145C8EEf6A40431',
        '0xd203De32170130082896b4111eDF825a4774c18E',
      ],
    },
    '0xb554A55358fF0382Fb21F0a478C3546d1106Be8c': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0xd203De32170130082896b4111eDF825a4774c18E': {
      decimals: 18,
      symbol: 'WETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x96419929d7949D6A801A6909c145C8EEf6A40431',
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      ],
    },
    '0xd22C0a4Af486C7FA08e282E9eB5f30F9AaA62C95': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0xf74195Bb8a5cf652411867c5C2C5b8C2a402be35': {
      decimals: 18,
      symbol: 'DAI',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d',
        '0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc',
        '0x6B4712AE9797C199edd44F897cA09BC57628a1CF',
      ],
    },
  },
  '1088': {
    '0x17C09cfC96C865CF546d73365Cedb6dC66986963': {
      decimals: 18,
      symbol: 'JEWEL',
      origin: ['JEWEL'],
      destination: ['JEWEL'],
      swappable: [],
    },
    '0x420000000000000000000000000000000000000A': {
      decimals: 18,
      symbol: 'WETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: ['0x931B8f17764362A3325D30681009f0eDd6211231'],
    },
    '0x67C10C397dD0Ba417329543c1a40eb48AAa7cd00': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0x931B8f17764362A3325D30681009f0eDd6211231': {
      decimals: 18,
      symbol: 'nETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: ['0x420000000000000000000000000000000000000A'],
    },
    '0x961318Fc85475E125B99Cc9215f62679aE5200aB': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: ['0xEA32A96608495e54156Ae48931A7c20f0dcc1a21'],
    },
    '0xE3c82A836Ec85311a433fBd9486EfAF4b1AFbF48': {
      decimals: 18,
      symbol: 'JUMP',
      origin: ['JUMP'],
      destination: ['JUMP'],
      swappable: [],
    },
    '0xEA32A96608495e54156Ae48931A7c20f0dcc1a21': {
      decimals: 6,
      symbol: 'm.USDC',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: ['0x961318Fc85475E125B99Cc9215f62679aE5200aB'],
    },
    '0xFB21B70922B9f6e3C6274BcD6CB1aa8A0fe20B80': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
  },
  '1284': {
    '0x0DB6729C03C85B0708166cA92801BcB5CAc781fC': {
      decimals: 18,
      symbol: 'veSOLAR',
      origin: ['veSOLAR'],
      destination: ['veSOLAR'],
      swappable: [],
    },
    '0x1d4C2a246311bB9f827F4C768e277FF5787B7D7E': {
      decimals: 18,
      symbol: 'MOVR',
      origin: ['MOVR'],
      destination: ['MOVR'],
      swappable: [],
    },
    '0x3192Ae73315c3634Ffa217f71CF6CBc30FeE349A': {
      decimals: 18,
      symbol: 'WETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [],
    },
    '0xA1f8890E39b4d8E33efe296D698fe42Fb5e59cC3': {
      decimals: 18,
      symbol: 'AVAX',
      origin: ['AVAX'],
      destination: ['AVAX'],
      swappable: [],
    },
    '0xA46aDF6D5881ca0b8596EDadF8f058F8c16d8B68': {
      decimals: 18,
      symbol: 'H2O',
      origin: ['H2O'],
      destination: ['H2O'],
      swappable: [],
    },
    '0xD2666441443DAa61492FFe0F37717578714a4521': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0xDd47A348AB60c61Ad6B60cA8C31ea5e00eBfAB4F': {
      decimals: 18,
      symbol: 'synFRAX',
      origin: ['synFRAX'],
      destination: ['synFRAX'],
      swappable: [],
    },
    '0xF44938b0125A6662f9536281aD2CD6c499F22004': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
  },
  '1285': {
    '0x3bF21Ce864e58731B6f28D68d5928BcBEb0Ad172': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0x76906411D07815491A5E577022757aD941fb5066': {
      decimals: 18,
      symbol: 'veSOLAR',
      origin: ['veSOLAR'],
      destination: ['veSOLAR'],
      swappable: [],
    },
    '0x98878B06940aE243284CA214f92Bb71a2b032B8A': {
      decimals: 18,
      symbol: 'WMOVR',
      origin: ['MOVR'],
      destination: ['MOVR'],
      swappable: [],
    },
    '0x9c0a820bb01e2807aCcd1c682d359e92DDd41403': {
      decimals: 18,
      symbol: 'H2O',
      origin: ['H2O'],
      destination: ['H2O'],
      swappable: [],
    },
    '0xE96AC70907ffF3Efee79f502C985A7A21Bce407d': {
      decimals: 18,
      symbol: 'synFRAX',
      origin: ['synFRAX'],
      destination: ['synFRAX'],
      swappable: [],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'MOVR',
      origin: ['MOVR'],
      destination: ['MOVR'],
      swappable: [],
    },
    '0xd80d8688b02B3FD3afb81cDb124F188BB5aD0445': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
  },
  '2000': {
    '0x10D70831f9C3c11c5fe683b2f1Be334503880DB6': {
      decimals: 18,
      symbol: 'FRAX',
      origin: ['synFRAX'],
      destination: ['synFRAX'],
      swappable: [],
    },
    '0x1555C68Be3b22cdcCa934Ae88Cb929Db40aB311d': {
      decimals: 18,
      symbol: 'BUSD',
      origin: ['BUSD'],
      destination: ['BUSD'],
      swappable: [],
    },
    '0x7f8e71DD5A7e445725F0EF94c7F01806299e877A': {
      decimals: 6,
      symbol: 'USDT',
      origin: ['USDT'],
      destination: ['USDT'],
      swappable: [],
    },
    '0x85C2D3bEBffD83025910985389aB8aD655aBC946': {
      decimals: 6,
      symbol: 'USDC',
      origin: ['USDC'],
      destination: ['USDC'],
      swappable: [],
    },
    '0x868055ADFA27D331d5b69b1BF3469aDAAc3ba7f2': {
      decimals: 18,
      symbol: 'NFD',
      origin: ['NFD'],
      destination: ['NFD'],
      swappable: [],
    },
    '0x9F4614E4Ea4A0D7c4B1F946057eC030beE416cbB': {
      decimals: 18,
      symbol: 'WETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [],
    },
    '0xB3306f03595490e5cC3a1b1704a5a158D3436ffC': {
      decimals: 18,
      symbol: 'DAI',
      origin: ['DAI'],
      destination: ['DAI'],
      swappable: [],
    },
    '0xD0c6179c43C00221915f1a61f8eC06A5Aa32F9EC': {
      decimals: 8,
      symbol: 'WBTC',
      origin: ['WBTC'],
      destination: ['WBTC'],
      swappable: [],
    },
    '0xDfA53EeBA61D69E1D2b56b36d78449368F0265c1': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
  },
  '7700': {
    '0x09fEC30669d63A13c666d2129230dD5588E2e240': {
      decimals: 18,
      symbol: 'nETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [],
    },
    '0x4e71A2E537B7f9D9413D3991D37958c0b5e1e503': {
      decimals: 18,
      symbol: 'NOTE',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd',
        '0xD8836aF2e565D3Befce7D906Af63ee45a57E8f80',
        '0xd567B3d7B8FE3C79a1AD8dA978812cfC4Fa05e75',
      ],
    },
    '0x555982d2E211745b96736665e19D9308B615F78e': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd': {
      decimals: 6,
      symbol: 'USDC',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x4e71A2E537B7f9D9413D3991D37958c0b5e1e503',
        '0xD8836aF2e565D3Befce7D906Af63ee45a57E8f80',
        '0xd567B3d7B8FE3C79a1AD8dA978812cfC4Fa05e75',
      ],
    },
    '0xD8836aF2e565D3Befce7D906Af63ee45a57E8f80': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x4e71A2E537B7f9D9413D3991D37958c0b5e1e503',
        '0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd',
        '0xd567B3d7B8FE3C79a1AD8dA978812cfC4Fa05e75',
      ],
    },
    '0xd567B3d7B8FE3C79a1AD8dA978812cfC4Fa05e75': {
      decimals: 6,
      symbol: 'USDT',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x4e71A2E537B7f9D9413D3991D37958c0b5e1e503',
        '0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd',
        '0xD8836aF2e565D3Befce7D906Af63ee45a57E8f80',
      ],
    },
  },
  '8217': {
    '0x078dB7827a5531359f6CB63f62CFA20183c4F10c': {
      decimals: 18,
      symbol: 'DAI',
      origin: ['DAI'],
      destination: ['DAI'],
      swappable: [],
    },
    '0x30C103f8f5A3A732DFe2dCE1Cc9446f545527b43': {
      decimals: 18,
      symbol: 'JEWEL',
      origin: ['JEWEL'],
      destination: ['JEWEL'],
      swappable: [],
    },
    '0x5819b6af194A78511c79C85Ea68D2377a7e9335f': {
      decimals: 18,
      symbol: 'WKLAY',
      origin: ['KLAY'],
      destination: ['KLAY'],
      swappable: [],
    },
    '0x6270B58BE569a7c0b8f47594F191631Ae5b2C86C': {
      decimals: 6,
      symbol: 'USDC',
      origin: ['USDC'],
      destination: ['USDC'],
      swappable: [],
    },
    '0xCD6f29dC9Ca217d0973d3D21bF58eDd3CA871a86': {
      decimals: 18,
      symbol: 'WETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [],
    },
    '0xCd8fE44A29Db9159dB36f96570d7A4d91986f528': {
      decimals: 18,
      symbol: 'AVAX',
      origin: ['AVAX'],
      destination: ['AVAX'],
      swappable: [],
    },
    '0xDCbacF3f7a069922E677912998c8d57423C37dfA': {
      decimals: 8,
      symbol: 'WBTC',
      origin: ['WBTC'],
      destination: ['WBTC'],
      swappable: [],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'KLAY',
      origin: ['KLAY'],
      destination: ['KLAY'],
      swappable: [],
    },
    '0xd6dAb4CfF47dF175349e6e7eE2BF7c40Bb8C05A3': {
      decimals: 6,
      symbol: 'USDT',
      origin: ['USDT'],
      destination: ['USDT'],
      swappable: [],
    },
    '0xe82f87ba4E97b2796aA0Fa4eFB06e8f0d2EB4FE1': {
      decimals: 8,
      symbol: 'BTC.b',
      origin: ['BTCB'],
      destination: ['BTCB'],
      swappable: [],
    },
    '0xfbEd1AbB3aD0f8C467068De9fDE905887e8C9118': {
      decimals: 18,
      symbol: 'LINK',
      origin: ['LINK'],
      destination: ['LINK'],
      swappable: [],
    },
  },
  '8453': {
    '0x417Ac0e078398C154EdFadD9Ef675d30Be60Af93': {
      decimals: 18,
      symbol: 'crvUSD',
      origin: ['CCTP.USDC'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
        '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
        '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
      ],
    },
    '0x4200000000000000000000000000000000000006': {
      decimals: 18,
      symbol: 'WETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        '0xb554A55358fF0382Fb21F0a478C3546d1106Be8c',
      ],
    },
    '0x432036208d2717394d2614d6697c46DF3Ed69540': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb': {
      decimals: 18,
      symbol: 'DAI',
      origin: ['CCTP.USDC'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x417Ac0e078398C154EdFadD9Ef675d30Be60Af93',
        '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
        '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
      ],
    },
    '0x6B4712AE9797C199edd44F897cA09BC57628a1CF': {
      decimals: 18,
      symbol: 'UNIDX',
      origin: ['UNIDX'],
      destination: ['UNIDX'],
      swappable: [],
    },
    '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913': {
      decimals: 6,
      symbol: 'USDC',
      origin: ['CCTP.USDC'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x417Ac0e078398C154EdFadD9Ef675d30Be60Af93',
        '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
        '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
        '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
      ],
    },
    '0xEB466342C4d449BC9f53A865D5Cb90586f405215': {
      decimals: 6,
      symbol: 'axlUSDC',
      origin: ['CCTP.USDC'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x417Ac0e078398C154EdFadD9Ef675d30Be60Af93',
        '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
        '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
      ],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'ETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x4200000000000000000000000000000000000006',
        '0xb554A55358fF0382Fb21F0a478C3546d1106Be8c',
      ],
    },
    '0xb554A55358fF0382Fb21F0a478C3546d1106Be8c': {
      decimals: 18,
      symbol: 'nETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x4200000000000000000000000000000000000006',
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      ],
    },
    '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA': {
      decimals: 6,
      symbol: 'USDbC',
      origin: ['CCTP.USDC'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x417Ac0e078398C154EdFadD9Ef675d30Be60Af93',
        '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
        '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        '0xEB466342C4d449BC9f53A865D5Cb90586f405215',
      ],
    },
  },
  '42161': {
    '0x080F6AEd32Fc474DD5717105Dba5ea57268F46eb': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0x0877154a755B24D499B8e2bD7ecD54d3c92BA433': {
      decimals: 18,
      symbol: 'NEWO',
      origin: ['NEWO'],
      destination: ['NEWO'],
      swappable: [],
    },
    '0x087d18A77465c34CDFd3a081a2504b7E86CE4EF8': {
      decimals: 18,
      symbol: 'SDT',
      origin: ['SDT'],
      destination: ['SDT'],
      swappable: [],
    },
    '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F': {
      decimals: 18,
      symbol: 'FRAX',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x2913E812Cf0dcCA30FB28E6Cac3d2DCFF4497688',
        '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
        '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
        '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      ],
    },
    '0x2913E812Cf0dcCA30FB28E6Cac3d2DCFF4497688': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
        '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
        '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
        '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      ],
    },
    '0x2CaB3abfC1670D1a452dF502e216a66883cDf079': {
      decimals: 18,
      symbol: 'L2DAO',
      origin: ['L2DAO'],
      destination: ['L2DAO'],
      swappable: [],
    },
    '0x3ea9B0ab55F34Fb188824Ee288CeaEfC63cf908e': {
      decimals: 18,
      symbol: 'nETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      ],
    },
    '0x51318B7D00db7ACc4026C88c3952B66278B6A67F': {
      decimals: 18,
      symbol: 'PLS',
      origin: ['PLS'],
      destination: ['PLS'],
      swappable: [],
    },
    '0x5429706887FCb58a595677B73E9B0441C25d993D': {
      decimals: 18,
      symbol: 'UNIDX',
      origin: ['UNIDX'],
      destination: ['UNIDX'],
      swappable: [],
    },
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1': {
      decimals: 18,
      symbol: 'WETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x3ea9B0ab55F34Fb188824Ee288CeaEfC63cf908e',
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      ],
    },
    '0x8D9bA570D6cb60C7e3e0F31343Efe75AB8E65FB1': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0xA54B8e178A49F8e5405A4d44Bb31F496e5564A05': {
      decimals: 18,
      symbol: 'PEPE',
      origin: ['PEPE'],
      destination: ['PEPE'],
      swappable: [],
    },
    '0xD1c6f989e9552DB523aBAE2378227fBb059a3976': {
      decimals: 18,
      symbol: 'H2O',
      origin: ['H2O'],
      destination: ['H2O'],
      swappable: [],
    },
    '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1': {
      decimals: 18,
      symbol: 'DAI',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
        '0x2913E812Cf0dcCA30FB28E6Cac3d2DCFF4497688',
        '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
        '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      ],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'ETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x3ea9B0ab55F34Fb188824Ee288CeaEfC63cf908e',
        '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      ],
    },
    '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8': {
      decimals: 6,
      symbol: 'USDC.e',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
        '0x2913E812Cf0dcCA30FB28E6Cac3d2DCFF4497688',
        '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
        '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      ],
    },
    '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9': {
      decimals: 6,
      symbol: 'USDT',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
        '0x2913E812Cf0dcCA30FB28E6Cac3d2DCFF4497688',
        '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
        '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
        '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      ],
    },
    '0xa684cd057951541187f288294a1e1C2646aA2d24': {
      decimals: 18,
      symbol: 'VSTA',
      origin: ['VSTA'],
      destination: ['VSTA'],
      swappable: [],
    },
    '0xaf88d065e77c8cC2239327C5EDb3A432268e5831': {
      decimals: 6,
      symbol: 'USDC',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
        '0x2913E812Cf0dcCA30FB28E6Cac3d2DCFF4497688',
        '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
        '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
        '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      ],
    },
    '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a': {
      decimals: 18,
      symbol: 'GMX',
      origin: ['GMX'],
      destination: ['GMX'],
      swappable: [],
    },
  },
  '43114': {
    '0x152b9d0FdC40C096757F570A51E494bd4b943E50': {
      decimals: 8,
      symbol: 'BTC.b',
      origin: ['BTCB'],
      destination: ['BTCB'],
      swappable: [],
    },
    '0x19E1ae0eE35c0404f835521146206595d37981ae': {
      decimals: 18,
      symbol: 'nETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: ['0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB'],
    },
    '0x1f1E7c893855525b303f99bDF5c3c05Be09ca251': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0x321E7092a180BB43555132ec53AaA65a5bF84251': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB': {
      decimals: 18,
      symbol: 'WETH.e',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: ['0x19E1ae0eE35c0404f835521146206595d37981ae'],
    },
    '0x4Bfc90322dD638F81F034517359BD447f8E0235a': {
      decimals: 18,
      symbol: 'NEWO',
      origin: ['NEWO'],
      destination: ['NEWO'],
      swappable: [],
    },
    '0x62edc0692BD897D2295872a9FFCac5425011c661': {
      decimals: 18,
      symbol: 'GMX',
      origin: ['GMX'],
      destination: ['GMX'],
      swappable: [],
    },
    '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7': {
      decimals: 6,
      symbol: 'USDT',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
        '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        '0xCFc37A6AB183dd4aED08C204D1c2773c0b1BDf46',
        '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
        '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
      ],
    },
    '0x997Ddaa07d716995DE90577C123Db411584E5E46': {
      decimals: 18,
      symbol: 'JEWEL',
      origin: ['JEWEL'],
      destination: ['JEWEL'],
      swappable: [],
    },
    '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664': {
      decimals: 6,
      symbol: 'USDC.e',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        '0xCFc37A6AB183dd4aED08C204D1c2773c0b1BDf46',
        '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
        '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
      ],
    },
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7': {
      decimals: 18,
      symbol: 'WAVAX',
      origin: ['AVAX'],
      destination: ['AVAX'],
      swappable: [],
    },
    '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E': {
      decimals: 6,
      symbol: 'USDC',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
        '0xCFc37A6AB183dd4aED08C204D1c2773c0b1BDf46',
        '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
        '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
      ],
    },
    '0xC6b11a4Fd833d1117E9D312c02865647cd961107': {
      decimals: 18,
      symbol: 'H2O',
      origin: ['H2O'],
      destination: ['H2O'],
      swappable: [],
    },
    '0xCCBf7c451F81752F7d2237F2c18C371E6e089E69': {
      decimals: 18,
      symbol: 'SDT',
      origin: ['SDT'],
      destination: ['SDT'],
      swappable: [],
    },
    '0xCFc37A6AB183dd4aED08C204D1c2773c0b1BDf46': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC', 'nUSD'],
      swappable: [
        '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
        '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
        '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
      ],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'AVAX',
      origin: ['AVAX'],
      destination: ['AVAX'],
      swappable: [],
    },
    '0xc2Bf0A1f7D8Da50D608bc96CF701110d4A438312': {
      decimals: 18,
      symbol: 'SFI',
      origin: ['SFI'],
      destination: ['SFI'],
      swappable: [],
    },
    '0xc7198437980c041c805A1EDcbA50c1Ce5db95118': {
      decimals: 6,
      symbol: 'USDT.e',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
        '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        '0xCFc37A6AB183dd4aED08C204D1c2773c0b1BDf46',
        '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
      ],
    },
    '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70': {
      decimals: 18,
      symbol: 'DAI.e',
      origin: ['CCTP.USDC', 'nUSD'],
      destination: ['CCTP.USDC'],
      swappable: [
        '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
        '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        '0xCFc37A6AB183dd4aED08C204D1c2773c0b1BDf46',
        '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      ],
    },
    '0xf1293574EE43950E7a8c9F1005Ff097A9A713959': {
      decimals: 18,
      symbol: 'NFD',
      origin: ['NFD'],
      destination: ['NFD'],
      swappable: [],
    },
  },
  '53935': {
    '0x2Df041186C844F8a2e2b63F16145Bc6Ff7d23E25': {
      decimals: 18,
      symbol: 'FTM',
      origin: ['FTM'],
      destination: ['FTM'],
      swappable: [],
    },
    '0x3AD9DFE640E1A9Cc1D9B0948620820D975c3803a': {
      decimals: 18,
      symbol: 'USDC',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [],
    },
    '0x7516EB8B8Edfa420f540a162335eACF3ea05a247': {
      decimals: 8,
      symbol: 'BTC.b',
      origin: ['BTCB'],
      destination: ['BTCB'],
      swappable: [],
    },
    '0x77f2656d04E158f915bC22f07B779D94c1DC47Ff': {
      decimals: 18,
      symbol: 'xJEWEL',
      origin: ['xJEWEL'],
      destination: ['xJEWEL'],
      swappable: [],
    },
    '0x97855Ba65aa7ed2F65Ed832a776537268158B78a': {
      decimals: 18,
      symbol: 'KLAY',
      origin: ['KLAY'],
      destination: ['KLAY'],
      swappable: [],
    },
    '0xB57B60DeBDB0b8172bb6316a9164bd3C695F133a': {
      decimals: 18,
      symbol: 'AVAX',
      origin: ['AVAX'],
      destination: ['AVAX'],
      swappable: [],
    },
    '0xCCb93dABD71c8Dad03Fc4CE5559dC3D89F67a260': {
      decimals: 18,
      symbol: 'WJEWEL',
      origin: ['JEWEL'],
      destination: ['JEWEL'],
      swappable: [],
    },
    '0xD17a41Cd199edF1093A9Be4404EaDe52Ec19698e': {
      decimals: 18,
      symbol: 'MATIC',
      origin: ['MATIC'],
      destination: ['MATIC'],
      swappable: [],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'JEWEL',
      origin: ['JEWEL'],
      destination: ['JEWEL'],
      swappable: [],
    },
    '0xfBDF0E31808d0aa7b9509AA6aBC9754E48C58852': {
      decimals: 18,
      symbol: 'ETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [],
    },
  },
  '81457': {
    '0x3194B0A295D87fDAA54DF852c248F7a6BAF6c6e0': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: ['0x4300000000000000000000000000000000000003'],
    },
    '0x4300000000000000000000000000000000000003': {
      decimals: 18,
      symbol: 'USDB',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: ['0x3194B0A295D87fDAA54DF852c248F7a6BAF6c6e0'],
    },
    '0x4300000000000000000000000000000000000004': {
      decimals: 18,
      symbol: 'WETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        '0xce971282fAAc9faBcF121944956da7142cccC855',
      ],
    },
    '0x9592f08387134e218327E6E8423400eb845EdE0E': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE': {
      decimals: 18,
      symbol: 'ETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x4300000000000000000000000000000000000004',
        '0xce971282fAAc9faBcF121944956da7142cccC855',
      ],
    },
    '0xce971282fAAc9faBcF121944956da7142cccC855': {
      decimals: 18,
      symbol: 'nETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [
        '0x4300000000000000000000000000000000000004',
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
      ],
    },
  },
  '1313161554': {
    '0x07379565cD8B0CaE7c60Dc78e7f601b34AF2A21c': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
        '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
      ],
    },
    '0x4988a896b1227218e4A686fdE5EabdcAbd91571f': {
      decimals: 6,
      symbol: 'USDT.e',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x07379565cD8B0CaE7c60Dc78e7f601b34AF2A21c',
        '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
      ],
    },
    '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802': {
      decimals: 6,
      symbol: 'USDC.e',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [
        '0x07379565cD8B0CaE7c60Dc78e7f601b34AF2A21c',
        '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
      ],
    },
    '0xd80d8688b02B3FD3afb81cDb124F188BB5aD0445': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
  },
  '1666600000': {
    '0x0b5740c6b4a97f90eF2F0220651Cca420B868FfB': {
      decimals: 18,
      symbol: 'nETH',
      origin: ['nETH'],
      destination: ['nETH'],
      swappable: [],
    },
    '0x1852F70512298d56e9c8FDd905e02581E04ddb2a': {
      decimals: 18,
      symbol: 'synFRAX',
      origin: ['synFRAX'],
      destination: ['synFRAX'],
      swappable: [],
    },
    '0x28b42698Caf46B4B012CF38b6C75867E0762186D': {
      decimals: 18,
      symbol: 'synJEWEL',
      origin: ['JEWEL'],
      destination: ['JEWEL'],
      swappable: ['0x72Cb10C6bfA5624dD07Ef608027E366bd690048F'],
    },
    '0x67C10C397dD0Ba417329543c1a40eb48AAa7cd00': {
      decimals: 18,
      symbol: 'gOHM',
      origin: ['gOHM'],
      destination: ['gOHM'],
      swappable: [],
    },
    '0x72Cb10C6bfA5624dD07Ef608027E366bd690048F': {
      decimals: 18,
      symbol: 'JEWEL',
      origin: ['JEWEL'],
      destination: ['JEWEL'],
      swappable: ['0x28b42698Caf46B4B012CF38b6C75867E0762186D'],
    },
    '0xA9cE83507D872C5e1273E745aBcfDa849DAA654F': {
      decimals: 18,
      symbol: 'xJEWEL',
      origin: ['xJEWEL'],
      destination: ['xJEWEL'],
      swappable: [],
    },
    '0xD9eAA386cCD65F30b77FF175F6b52115FE454fD6': {
      decimals: 18,
      symbol: 'AVAX',
      origin: ['AVAX'],
      destination: ['AVAX'],
      swappable: ['0xb12c13e66AdE1F72f71834f2FC5082Db8C091358'],
    },
    '0xE3c82A836Ec85311a433fBd9486EfAF4b1AFbF48': {
      decimals: 18,
      symbol: 'SDT',
      origin: ['SDT'],
      destination: ['SDT'],
      swappable: [],
    },
    '0xE55e19Fb4F2D85af758950957714292DAC1e25B2': {
      decimals: 18,
      symbol: 'SYN',
      origin: ['SYN'],
      destination: ['SYN'],
      swappable: [],
    },
    '0xED2a7edd7413021d440b09D654f3b87712abAB66': {
      decimals: 18,
      symbol: 'nUSD',
      origin: ['nUSD'],
      destination: ['nUSD'],
      swappable: [],
    },
    '0xb12c13e66AdE1F72f71834f2FC5082Db8C091358': {
      decimals: 18,
      symbol: 'AVAX',
      origin: ['AVAX'],
      destination: ['AVAX'],
      swappable: ['0xD9eAA386cCD65F30b77FF175F6b52115FE454fD6'],
    },
  },
}

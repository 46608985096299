import * as ALL_CHAINS from '@constants/chains/master'

export const CHAINLINK_ETH_PRICE_ADDRESSES = {
  [ALL_CHAINS.ETH.id]: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
}

export const CHAINLINK_AVAX_PRICE_ADDRESSES = {
  [ALL_CHAINS.ETH.id]: '0xFF3EEb22B5E3dE6e705b44749C2559d704923FD7',
}

export const CHAINLINK_METIS_PRICE_ADDRESSES = {
  [ALL_CHAINS.METIS.id]: '0xd4a5bb03b5d66d9bf81507379302ac2c2dfdfa6d',
}

export const CHAINLINK_GMX_PRICE_ADDRESSES = {
  [ALL_CHAINS.ARBITRUM.id]: '0xDB98056FecFff59D032aB628337A4887110df3dB',
}
